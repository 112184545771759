<template>
    <a-spin :spinning="loading">
        <a-carousel :autoplay="true" dotsClass="dot" style="min-height:360px">
            <div v-for="item in news" :key="item.id">
              <img :src="item.pictures" style="width:100%"/>
            </div>
        </a-carousel>
        <a-modal
            title="详情"
            v-model="newsdetailmodal.visible"
            width="1100px"
            :footer="false"
            destroyOnClose
            :maskClosable="false"
        >
            <newsdetail @callback="closenewsmodal" :id="newsdetailmodal.id" />
        </a-modal>
    </a-spin>
</template>
<script>
import newsApi from "@/api/cms/newsArticle"
import newsdetail from "./newsdetail.vue"
export default {
    name:"newscard",
    props:{
        category:{
            type:String
        },
        size:{
            type:Number,
            default:10
        }
    },
    data(){
        return {
            news:[],
            loading:false,
            newsdetailmodal:{
                visible:false,
                id:0
            },
        }
    },
    components:{newsdetail},
    mounted(){
        this.getList();
    },
    methods:{
        gotolist(){
            this.$router.push({name:'newslist',query:{categoryName:this.category}})
        },
        closenewsmodal(){
            this.newsdetailmodal.visible=false;
        },
        shownewsdetail(id){
            this.newsdetailmodal.id=id;
            this.newsdetailmodal.visible=true;
        },
        getList(){
            this.loading=true;
            newsApi.pageList(1,this.size,{categoryName:this.category}).then(res=>{
                this.loading=false;
                if (res.errorCode == this.$commons.RespCode.success) {
                    this.news=res.data.records;
                }
            }) 
        }
    }
}
</script>