<template>
  <a-spin :spinning="loading">
    <a-card :title="category" style="min-height: 513px">
      <a slot="extra" href="#" @click="gotolist">更多</a>
      <p v-for="item in news" :key="item.id" class="cus-item">
        <a
          class="overflow align-center justify-between"
          href="javascript:void(0)"
          @click="shownewsdetail(item.id)"
          :title="item.title"
        >
          <p class="title text-cut">{{ item.title }}</p>
          <span class="time right">{{
            dayjs(item.publishTime).format("MM-DD")
          }}</span>
        </a>
      </p>
    </a-card>
    <a-modal
      title="详情"
      v-model="newsdetailmodal.visible"
      width="70%"
      :dialog-style="{ top: '10px' }"
      :footer="false"
      destroyOnClose
      :maskClosable="false"
    >
      <newsdetail
        @callback="closenewsmodal"
        :id="newsdetailmodal.id"
        :isZgyd="isZgyd"
      />
    </a-modal>
  </a-spin>
</template>
<script>
import dayjs from "dayjs";
import newsApi from "@/api/cms/newsArticle";
import newsdetail from "./newsdetail.vue";
export default {
  name: "newscard",
  props: {
    category: {
      type: String,
    },
    size: {
      type: Number,
      default: 10,
    },
    isZgyd: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      dayjs,
      news: [],
      loading: false,
      newsdetailmodal: {
        visible: false,
        id: 0,
      },
    };
  },
  components: { newsdetail },
  mounted() {
    this.getList();
  },
  methods: {
    gotolist() {
      this.$router.push({
        name: "newslist",
        query: { categoryName: this.category, isZgyd: this.isZgyd },
      });
    },
    closenewsmodal() {
      this.newsdetailmodal.visible = false;
    },
    shownewsdetail(id) {
      this.newsdetailmodal.id = id;
      this.newsdetailmodal.visible = true;
    },
    getList() {
      this.loading = true;
      newsApi
        .pageListForFront(1, this.size, { categoryName: this.category })
        .then((res) => {
          this.loading = false;
          if (res.errorCode == this.$commons.RespCode.success) {
            this.news = res.data.records;
          }
        });
    },
  },
};
</script>
