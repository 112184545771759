<template>
  <div style="background-color: #f0f2f5; height: 100%">
    <a-row :gutter="10">
      <a-col :span="8" style="height: 100%">
        <newscard category="通知公示" />
      </a-col>
      <a-col :span="8">
        <newscard category="院内动态"></newscard>
      </a-col>
      <a-col :span="8">
        <newscard category="职能职责"></newscard>
      </a-col>
    </a-row>
    <a-row :gutter="10" style="margin-top: 10px">
      <a-col :span="8">
        <newscard category="会议纪要"></newscard>
      </a-col>
      <a-col :span="8">
        <newscard category="党群建设"></newscard>
      </a-col>
      <a-col :span="8">
        <newscard category="规章制度"></newscard>
      </a-col>
    </a-row>
    <a-row style="margin-top: 10px">
      <a-col :span="24">
        <a-card
          :bordered="false"
          :bodyStyle="{ padding: 0, backgroundColor: '#f0f2f5' }"
        >
          <a-card-meta>
            <span
              slot="title"
              style="
                display: inline-block;
                margin: 16px 0;
                width: 100%;
                font-size: 20px;
                font-weight: 600;
                text-align: center;
              "
              >职工园地</span
            >
            <template slot="description">
              <a-row :gutter="10">
                <a-col :span="8">
                  <newscard category="科学研究" :isZgyd="true"></newscard>
                </a-col>
                <a-col :span="8">
                  <newscard category="生活日常" :isZgyd="true"></newscard>
                </a-col>
                <a-col :span="8">
                  <newscard category="社会团体" :isZgyd="true"></newscard>
                </a-col>
              </a-row>
            </template>
          </a-card-meta>
        </a-card>
      </a-col>
    </a-row>
  </div>
</template>
<script>
import newscard from "./work/newscard.vue";
import newscolcard from "./work/newscolcard.vue";
import newscarousel from "./work/imagecarousel";
// import newsscroll from './work/imagescroll.vue';
export default {
  name: "Home",
  components: { newscard, newscarousel, newscolcard },
};
</script>
<style lang="less">
.ant-card-bordered {
  border-radius: 10px;
  .ant-card-head-wrapper {
    display: block;
    height: auto;
    .ant-card-head-title {
      padding: 8px 12px 7px 12px;
      padding-bottom: 0px;
      height: 38px;
      position: absolute;
      top: 15px;
      background: #1d63b8;
      color: white;
      border-radius: 5px 5px 0px 0px;
    }
  }
}
.ant-carousel {
  .slick-slide {
    text-align: center;
    min-height: 360px;
    background: white;
    border-radius: 10px;
    overflow: hidden;
  }
  .slick-slide h3 {
    color: black;
  }
}
.dot {
  li {
    background: #1d63b8;
  }
  li.slick-active {
    button {
      background: #20528f !important;
    }
  }
}
.cus-item a {
  color: #333333;
  height: 21px;
  margin-top: 20px;
  &.overflow {
    overflow: hidden;
  }
  &.align-center {
    display: flex;
    align-items: center;
  }
  &.justify-between {
    display: flex;
    justify-content: space-between;
  }
  .title {
    width: 416px;
    font-size: 16px;
    color: #222222;
    flex: 1;
    margin-right: 30px;
  }
  .title:hover {
    color: #1890ff;
  }
  .text-cut {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
  .time {
    width: 39px;
    font-size: 14px;
    color: #999999;
  }
}
</style>