<template>
  <a-spin :spinning="loading">
    <a-card :title="category" style="min-height: 200px">
      <a slot="extra" href="#" @click="gotolist">更多</a>
      <a-row>
        <a-col
          :span="24 / colNum"
          v-for="i in colNum"
          :key="i"
          :style="{
            'padding-right': i == 1 ? '36px' : i == 2 ? '19px' : '0',
            'padding-left': i == 1 ? '0' : i == 2 ? '19px' : '41px',
          }"
        >
          <template v-for="(item, index) in news">
            <p
              class="cus-item"
              :key="item.id"
              v-if="index < colRowNum * i && index >= colRowNum * (i - 1)"
            >
              <a
                class="overflow align-center justify-between"
                href="javascript:void(0)"
                @click="shownewsdetail(item.id)"
                :title="item.title"
              >
                <p class="title text-cut">{{ item.title }}</p>
                <span class="time right">{{
                  dayjs(item.publishTime).format("MM-DD")
                }}</span>
              </a>
            </p>
          </template>
        </a-col>
      </a-row>
    </a-card>
    <a-modal
      title="详情"
      v-model="newsdetailmodal.visible"
      width="70%"
      :footer="false"
      destroyOnClose
      :maskClosable="false"
    >
      <newsdetail @callback="closenewsmodal" :isZgyd="isZgyd" :id="newsdetailmodal.id" />
    </a-modal>
  </a-spin>
</template>
<script>
import dayjs from "dayjs";
import newsApi from "@/api/cms/newsArticle";
import newsdetail from "./newsdetail.vue";
export default {
  name: "newscard",
  props: {
    category: {
      type: String,
    },
    // 总条数
    size: {
      type: Number,
      default: 10,
    },
    // 栏数
    colNum: {
      type: Number,
      default: 1,
    },
    // 每栏行数
    colRowNum: {
      type: Number,
      default: 10,
    },
  },
  data() {
    return {
      dayjs,
      news: [],
      loading: false,
      isZgyd:true,
      newsdetailmodal: {
        visible: false,
        id: 0,
      },
    };
  },
  components: { newsdetail },
  mounted() {
    this.getList();
  },
  methods: {
    gotolist() {
      this.$router.push({
        name: "newslist",
        query: { categoryName: this.category,isZgyd:true },
      });
    },
    closenewsmodal() {
      this.newsdetailmodal.visible = false;
    },
    shownewsdetail(id) {
      this.newsdetailmodal.id = id;
      this.newsdetailmodal.visible = true;
    },
    getList() {
      this.loading = true;
      newsApi
        .pageListForFront(1, this.size, { categoryName: this.category })
        .then((res) => {
          this.loading = false;
          if (res.errorCode == this.$commons.RespCode.success) {
            this.news = res.data.records;
          }
        });
    },
  },
};
</script>
